<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="autoskolaData.avatar"
          :text="avatarText(autoskolaData.ime)"
          :variant="`light-${resolveUserRoleVariant(autoskolaData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ autoskolaData.ime }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">{{ $t('Izmeni') }}</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="ukloniLogo"
        >
          <span class="d-none d-sm-inline">{{ $t('Ukloni') }}</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Ime -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Ime')"
            label-for="ime"
          >
            <b-form-input
              id="ime"
              v-model="autoskolaData.ime"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Adresa -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Adresa')"
            label-for="adresa"
          >
            <b-form-input
              id="adresa"
              v-model="autoskolaData.adresa"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Grad -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Grad')"
            label-for="grad"
          >
            <b-form-input
              id="grad"
              v-model="autoskolaData.grad"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Država -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Drzava')"
            label-for="drzava"
          >
            <b-form-input
              id="drzava"
              v-model="autoskolaData.drzava"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Kod pravnog lica -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('kod_pr_lica')"
            label-for="kod_pr_lica"
          >
            <b-form-input
              id="kod_pr_lica"
              v-model="autoskolaData.kod_pr_lica"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('PDV')"
            label-for="pdv"
          >
            <v-select
              v-model="autoskolaData.pdv"
              :options="pdv_opcije"
              :clearable="false"
              input-id="pdv"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="autoskolaData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Odgovorno lice -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('OdgovornoLice')"
            label-for="odg_lice"
          >
            <v-select
              v-model="odg_lice"
              :options="zaposleni"
              :clearable="false"
              input-id="odg_lice"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Telefon -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Telefon')"
            label-for="telefon"
          >
            <b-form-input
              id="telefon"
              v-model="autoskolaData.telefon"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Telefon -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Mobilni')"
            label-for="mobilni"
          >
            <b-form-input
              id="mobilni"
              v-model="autoskolaData.mobilni"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <!-- Field: Naziv pravnog lica -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('NazivPravnogLica')"
            label-for="naziv_pravnog_lica"
          >
            <b-form-textarea
              id="naziv_pravnog_lica"
              v-model="autoskolaData.naziv_pravnog_lica"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Sedište pravnog lica -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('SedistePravnogLica')"
            label-for="adresa_pravnog_lica"
          >
            <b-form-textarea
              id="adresa_pravnog_lica"
              v-model="autoskolaData.adresa_pravnog_lica"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="izmeniSkolu"
    >
      {{ $t('Snimi') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('Odustani') }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormTextarea,
  },
  props: {
    autoskolaData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zaposleni: [],
      odg_lice: [],
      pdv_opcije: ['da', 'ne'],
    }
  },
  mounted() {
    this.$http.get('/autoskole-administratori/zaposleni').then(response => {
      Object.entries(response.data).forEach(value => {
        if (value[1].id === this.odgovornoLlice) {
          this.odg_lice = { value: value[1].id, label: `${value[1].ime} ${value[1].prezime}` }
        }
        this.zaposleni.push({ value: value[1].id, label: `${value[1].ime} ${value[1].prezime}` })
      })
    })
  },
  methods: {
    izmeniSkolu() {
      this.autoskolaData.odg_lice = this.odg_lice.value
      const skolaData = this.autoskolaData
      this.$http.put('/autoskole-administratori/autoskola/', { data: skolaData })
        .then(res => {
          const userData = JSON.parse(localStorage.getItem('userData'))
          userData.avatar = res.data.avatar
          localStorage.setItem('userData', JSON.stringify(userData))
          window.location.reload()
          this.$router.replace('/dashboard/analytics')
        })
    },
    ukloniLogo() {
      this.autoskolaData.avatar = ''
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.autoskolaData.avatar = base64
    })
    const odgovornoLlice = props.autoskolaData.odg_lice

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      odgovornoLlice,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
